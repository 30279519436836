import useApi from '~/composables/useApi';
import { useCart } from '~/composables';
import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { ComposableFunctionArgs } from '~/composables/types';
import { Maybe, CustomerOrdersQueryVariables, CustomerOrdersQuery, ReorderItemsOutput, DataTableSearchParams } from '~/modules/GraphQL/types';
import { 
	CartOptionsSearchParams,
	ErpOrdersOutput,
	ErpCompaniesOutput,
	CartOptions,
	OrderCategory,
	UpdateCartOptionsInput
} from './types';
import erpCompanies from './erpCompanies.gql';
import erpOrders from './erpOrders.gql';
import cartOptions from './cartOptions.gql';
import updateCartOptions from './updateCartOptions.gql';
import reorderItemsMutation from './reorderItems.gql';
import { updateCartOptionsFormData } from './helpers/updateCartOptionsFormData';

export const useUserOrder = () => {
	const { app } = useContext();
	const { query, mutate } = useApi();
	const { cart, load: loadCart } = useCart();

	const loading = ref(false);
	const erpOrdersLoading = ref(false);

	const error = ref({
		search: null,
	});

	const getCartOptions = async (cartId = null, fresh = false): Promise<Maybe<CartOptions>> => {
		loading.value = true;

		if (fresh) {
			await Promise.all([loadCart({})]);
		}

		const searchParams: CartOptionsSearchParams = {
			filter: {
				cart_id: {
					eq: cartId ? cartId : cart.value.id
				},
			}
		};

		const { data } = await query<{ cartOptions: CartOptions }>(cartOptions, searchParams);

		loading.value = false;

		const item = data.cartOptions;

		return {
			cart_id: item.cart_id,
			bee_customer_id: item.bee_customer_id,
			seller_id: item.seller_id,
			category_id: item.category_id,
			project: item.project,
			customer_order_number: item.customer_order_number,
			country_id: item.country_id,
			location: item.location,
			is_corrosive_environment: item.is_corrosive_environment,
			warranty_id: item.warranty_id,
			requested_delivery_date: item.requested_delivery_date,
			our_reference_id: item.our_reference_id,
			customer_reference_id: item.customer_reference_id,
			approved_at: item.approved_at,
			approved_by: item.approved_by,
			additional_info: item.additional_info,
            dimming: item.dimming,
			categories: item.categories,
			warranty: item.warranty,
			countries: item.countries,
			sellers: item.sellers,
			our_references: item.our_references,
			customer_references: item.customer_references,
		}
	};

	const setCartOptions = async (options: UpdateCartOptionsInput): Promise<Maybe<CartOptions>> => {
		loading.value = true;
		// eslint-disable-next-line no-underscore-dangle
		const input = updateCartOptionsFormData(options);

		const { data } = await mutate<{ updateCartOptions: CartOptions }>(updateCartOptions, { UpdateCartOptionsInput: input });

		loading.value = false;

		const item = data.updateCartOptions;

		Logger.debug('[setCartOptions] response', { item });

		if (item === null) {
			return null;
		}

		return {
			cart_id: item.cart_id,
			bee_customer_id: item.bee_customer_id,
			seller_id: item.seller_id,
			category_id: item.category_id,
			project: item.project,
			customer_order_number: item.customer_order_number,
			country_id: item.country_id,
			location: item.location,
			is_corrosive_environment: item.is_corrosive_environment,
			warranty_id: item.warranty_id,
			requested_delivery_date: item.requested_delivery_date,
			our_reference_id: item.our_reference_id,
			customer_reference_id: item.customer_reference_id,
			approved_at: item.approved_at,
			approved_by: item.approved_by,
			additional_info: item.additional_info,
            dimming: item.dimming,
			categories: item.categories,
			warranty: item.warranty,
			countries: item.countries,
			sellers: item.sellers,
			our_references: item.our_references,
			customer_references: item.customer_references,
		}
	};

	const getErpCompanies = async (): Promise<Maybe<ErpCompaniesOutput>> => {
		erpOrdersLoading.value = true;

		const { data } = await query<{ erpCompanies: ErpCompaniesOutput }>(erpCompanies);

		erpOrdersLoading.value = false;

		return data.erpCompanies;
	};

	const getErpOrders = async (params): Promise<Maybe<ErpOrdersOutput>> => {
        const searchParams: DataTableSearchParams = {
            filter: {
                data_filter: {
                    eq: JSON.stringify(params)
                }
            }
        };

		erpOrdersLoading.value = true;

		const { data } = await query<{ erpOrders: ErpOrdersOutput }>(erpOrders, searchParams);

		erpOrdersLoading.value = false;

		const items = data.erpOrders;

		return {
			total: items.total,
			page: items.page,
			per_page: items.per_page,
			data: items.data,
			filtered_columns: items.filtered_columns,
		};
	};

	const search = async (params: ComposableFunctionArgs<CustomerOrdersQueryVariables>): Promise<Maybe<Array<CustomerOrdersQuery['customer']['orders']['items']>>> => {
		let results = null;

		try {
			loading.value = true;

			Logger.debug('[Magento] search user orders', { params });

			const { data } = await app.$vsf.$magento.api.customerOrders(params);

			Logger.debug('[Result]:', { data });

			results = data?.customer?.orders ?? null;
			error.value.search = null;
		} catch (err) {
			error.value.search = err;
			Logger.error('useRelatedProducts/search', err);
		} finally {
			loading.value = false;
		}

		return results;
	};

	const reorderItems = async (orderNumber: string, project: string) => {

		try {
			loading.value = true;
			const { data } = await mutate<{ reorderItemsMutation: ReorderItemsOutput }>(reorderItemsMutation, { orderNumber: orderNumber, project: project });

		} catch (err) {
		} finally {
			loading.value = false;
		}
	};

	return {
		search,
		loading,
		error,
		erpOrdersLoading,
		getErpCompanies,
		getErpOrders,
		getCartOptions,
		setCartOptions,
		reorderItems,
	};
};

export default useUserOrder;

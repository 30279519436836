import { gql } from 'graphql-request';

export default gql`
    query priceListFiles(
        $filter: PriceListFileFilterInput,
    ) {
        priceListFiles(filter: $filter) {
            data {
                price_list_file_id
                name
                alternative_price_list_file_id
                alternative_price_list_file_name
            }
        }
    }
`;
import { gql } from 'graphql-request';

export default gql`
    query erpOrders(
        $filter: DataTableFilterInput
    ) {
        erpOrders(filter: $filter) {
            total
            page
            per_page
            data {
                id
                order_number
                articles
                customer_id
                project
                status
                created_by
                approved_by
                created_at
                approved_at
                ready_by
                delivery_term
                payment_term
                warranty
                rows {
                    id
                    article
                    name
                    model_name
                    quantity
                    delivered
                    price
                    requested_delivery_at
                    estimated_release_at
                    approved_release_at
                    approved_production_at
                    created_at
                    object_type
                    is_cancelled
                }
            }
            filtered_columns {
                label
                field
                attribute_code
                abbreviation
                description
                hidden
                values {
                    uid
                    label
                    is_active
                    url
                    description
                }
                min
                max
                step
            }
        }
    }
`;